import "./NavHeader.scss";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useEffect, useState } from "react";

function NavHeader() {
  return (
    <Navbar expand="md" className="navbar" id="navbar">
      <Container fluid>
        <Navbar.Brand href="#pre-header">
          <img src="../../Assets/logo-header.svg" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Nav.Link href="#pre-header">Home</Nav.Link>
            <Nav.Link href="#about">About Us</Nav.Link>
            <Nav.Link href="#why">Why Choose Us</Nav.Link>
            <Nav.Link href="#serives">Serivces</Nav.Link>
            <Nav.Link href="#testimonial">Testimonials</Nav.Link>
            <Nav.Link href="#contact" className="contact-us">
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default NavHeader;
