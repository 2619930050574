import { Col, Row } from "react-bootstrap";
import "./Service.scss";

function Service() {
  return (
    <div id="serives">
      <h2 className="page-header">Our Services</h2>
      <Row className="serives">
        <Col lg={7} sm={0} className="col-img">
          <div className="img">
            <img src="../../Assets/serivce_girl.png" />
          </div>
        </Col>
        <Col lg={5} sm={12} className="col-con">
          <div className="service-container">
            <div className="service-item">
              <h2>01</h2>
              <div>
                <h3>Custom Travel Planning</h3>
                <p>
                  Let us design a travel itinerary just for you. Share your
                  preferences, and we’ll handle the rest.
                </p>
              </div>
            </div>
            <div className="service-item">
              <h2>02</h2>
              <div>
                <h3>Flight and Hotel Bookings</h3>
                <p>
                  Find and book the best flights and accommodations at
                  competitive prices.
                </p>
              </div>
            </div>
            <div className="service-item">
              <h2>03</h2>
              <div>
                <h3>Tour Packages</h3>
                <p>
                  Choose from a variety of pre-planned tours and packages for a
                  seamless travel experience.
                </p>
              </div>
            </div>
            <div className="service-item">
              <h2>04</h2>
              <div>
                <h3>Travel Insurance</h3>
                <p>
                  Travel with peace of mind with our comprehensive travel
                  insurance options.
                </p>
              </div>
            </div>
            <div className="service-item">
              <h2>05</h2>
              <div>
                <h3>Visa Assistance</h3>
                <p>
                  Get help with visa applications and requirements for your
                  destination.
                </p>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default Service;
