import "./PreHeader.scss";
import { Row, Col } from "react-bootstrap";
function PreHeader() {
  return (
    <Row id="pre-header">
      <Col sm={12} md={8} lg={6}>
        <Row>
          <Col sm={6} xs={12}>
            <a href="mailto:inf2prajans2018@gmail.com">
              <img src="../../Assets/sms.svg" />
              <span> inf2prajans2018@gmail.com</span>
            </a>
          </Col>
          <Col sm={6} xs={12}>
            <a href="https://wa.me/+919789396051" target="_blank">
              <img src="../../Assets/call.svg" />
              <span> +91 97893 96051(What'sApp)</span>
            </a>
          </Col>
        </Row>
      </Col>
      <Col sm={0} md={4} lg={6}></Col>
    </Row>
  );
}

export default PreHeader;
