import { Col, Row } from "react-bootstrap";
import "./Aboutus.scss";
import Destinations from "../Destinations/Destinations";

function Aboutus() {
  return (
    <div className="aboutus" id="about">
      <Destinations></Destinations>
      <div className="Parent">
        <div className="c-about">
          <h3>About Us</h3>

          <Row className="center">
            <Col className="description" xs={12} sm={12} md={6}>
              eTripEasy, we believe that travel should be easy and enjoyable.
              Founded by travel enthusiasts, we are dedicated to helping you
              create amazing travel experiences without the stress and hassle of
              planning. Our team of experts are passionate about finding the
              best destinations, accommodations, and activities tailored to your
              preferences.
              <img
                src="../../Assets/grey-logo.svg"
                alt="aboutlogo"
                className="aboutlogo"
              ></img>
            </Col>
            <Col className="imageright" xs={12} sm={12} md={6}>
              <img src="../../Assets/aboutimg.png" alt="AboutImage" />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default Aboutus;
