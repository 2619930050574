import React from "react";
import "./Why.scss";
import { Row } from "react-bootstrap";

function Why() {
  return (
    <Row className="why-container" id="why">
      <h2>Why Choose Us</h2>
      <div className="whysection">
        {[
          {
            src: "../../Assets/why1.png",
            text: "Personalised\nTravel Plans",
            description:
              "We tailor your trip according to your interests, budget, and schedule.",
          },
          {
            src: "../../Assets/image 5.png",
            text: "Expert Guidance",
            description:
              "Our experienced travel advisors are here to help you every step of the way.",
          },
          {
            src: "../../Assets/image 7.png",
            text: "Best Deals",
            description:
              "We provide you with the best travel deals and discounts.",
          },
          {
            src: "../../Assets/image 9.png",
            text: "24/7 Support",
            description: "We're here to assist you anytime, anywhere.",
          },
        ].map((item, index) => (
          <div key={index} className="parent">
            <div className="container">
              <img src={item.src} alt={item.text} />
              <div className="overlaytext">{item.text}</div>
            </div>
            <p>{item.description}</p>
          </div>
        ))}
      </div>
    </Row>
  );
}

export default Why;
