import "./Footer.scss";
import { Container, Nav, Navbar } from "react-bootstrap";
import { useEffect, useState } from "react";

function Footer() {
  return (
    <Navbar expand="md" className="footer-nav" id="footer-nav">
      <Container fluid>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Nav.Link href="#pre-header">Home</Nav.Link>
            <Nav.Link href="#about">About Us</Nav.Link>
            <Nav.Link href="#why">Why Choose Us</Nav.Link>
            <Nav.Link href="#serives">Serivces</Nav.Link>
            <Nav.Link href="#testimonial">Testimonials</Nav.Link>
            <Nav.Link href="#contact" className="contact-us">
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
export default Footer;
