import { Carousel, Col, Nav, Row, Toast } from "react-bootstrap";
import "./Contact.scss";
import emailjs from "@emailjs/browser";
import { useRef, useState } from "react";
import Footer from "../Footer/Footer";

function Contact() {
  const form: any = useRef();
  const [show, setShow] = useState(false);
  const [success, setsuccess] = useState(false);

  const sendEmail = (e: any) => {
    e.preventDefault();

    emailjs
      .sendForm("service_sg6tq76", "template_bowqegl", form.current, {
        publicKey: "BMaSvMeIPtgBJ8G0i",
      })
      .then(
        () => {
          form.current.reset();
          setShow(true);
          console.log("SUCCESS!");
        },
        (error) => {
          setsuccess(true);

          console.log("FAILED...", error.text);
        }
      );
  };
  return (
    <div>
      <Row id="contact">
        <Col sm={12} md={6}>
          <div className="left-con">
            <h2>Get in Touch</h2>
            <p>
              We’d love to hear from you! Whether you have a question, need
              assistance, or want to share your travel experiences, feel free to
              contact us.
            </p>
            <img src="../../Assets/girl.png" />
          </div>
        </Col>
        <Col sm={12} md={6}>
          <div className="right-con">
            <form ref={form} onSubmit={sendEmail}>
              <div className="form-row">
                <div className="form-group">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    required
                    placeholder="Your Name"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="tel"
                    id="number"
                    name="number"
                    required
                    placeholder="Contact Number"
                  />
                </div>
              </div>
              <div className="form-row">
                <div className="form-group">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    required
                    placeholder="Your Email"
                  />
                </div>
                <div className="form-group">
                  <input
                    type="text"
                    id="subject"
                    name="subject"
                    required
                    placeholder="Subject"
                  />
                </div>
              </div>
              <div className="form-group">
                <textarea
                  id="message"
                  name="message"
                  required
                  rows={6}
                  placeholder="Message"
                ></textarea>
              </div>
              <Row>
                <Toast
                  bg={"success"}
                  key={1}
                  onClose={() => setShow(false)}
                  show={show}
                  delay={3000}
                  autohide
                >
                  <Toast.Header>
                    <strong className="me-auto">
                      Thank you for Requesting us
                    </strong>
                  </Toast.Header>
                  <Toast.Body className={"text-white"}>
                    Our Team will contact you
                  </Toast.Body>
                </Toast>
              </Row>
              <Row>
                <Toast
                  bg={"danger"}
                  key={1}
                  onClose={() => setsuccess(false)}
                  show={success}
                  delay={3000}
                  autohide
                >
                  <Toast.Header>
                    <strong className="me-auto">
                      Sorry We are facing technical issue
                    </strong>
                  </Toast.Header>
                  <Toast.Body className={"text-white"}>
                    Please try later
                  </Toast.Body>
                </Toast>
              </Row>
              <button className="btn-theme">SUBMIT</button>
            </form>
          </div>
        </Col>
        <Footer></Footer>
        <div className="foot">
          <Row className="company">
            <Col md={9} sm={12}>
              <Row>
                <Col sm={6} md={4}>
                  <a href="mailto:inf2prajans2018@gmail.com">
                    <img src="../../Assets/sms.svg" />
                    <span> inf2prajans2018@gmail.com</span>
                  </a>
                </Col>
                <Col sm={6} md={4}>
                  <a href="https://wa.me/+919789396051" target="_blank">
                    <img src="../../Assets/call.svg" />
                    <span> +91 97893 96051(What'sApp)</span>
                  </a>
                </Col>
                <Col sm={12} md={4}>
                  <a href="">
                    <img src="../../Assets/location.svg" />
                    <span> No:113, 7 th Cross, Ganam Nagar. Thanjavur</span>
                  </a>
                </Col>
              </Row>
            </Col>
            <Col md={3} sm={12} className="follow">
              <Row>
                <Col md={4}>
                  <a href="">Follow Us</a>
                </Col>
                <Col md={2}>
                  <a href="">
                    <img src="../../Assets/x.svg" />
                  </a>
                </Col>
                <Col md={2}>
                  <a href="">
                    <img src="../../Assets/fb.svg" />
                  </a>
                </Col>
                <Col md={2}>
                  <a href="">
                    <img src="../../Assets/in.svg" />
                  </a>
                </Col>
                <Col md={2}>
                  <a href="">
                    <img src="../../Assets/insta.svg" />
                  </a>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="poweredby">
            <Col className="copy" md={6} sm={12}>
              &copy; 2024 etripeasy.com All rights reserved
            </Col>
            <Col className="dev" md={6} sm={12}>
              Developed By : Shanzio InfoTech
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  );
}
export default Contact;
