import { Carousel, Col, Row } from "react-bootstrap";
import "./Clients.scss";

function Clients() {
  const testimonials = [
    {
      name: "Jane D.",
      location: "United Kingdom",
      quote:
        "ETripEasy made our family vacation a breeze! From booking flights to planning activities, everything was perfect",
      image: "../../Assets/test-1.png", // replace with the correct path to your image
    },
    {
      name: "Ashwin Alexander",
      location: "Singapore",
      quote:
        "Thanks to ETripEasy, we had an unforgettable family holiday. Everything was seamless and stress-free.",
      image: "../../Assets/medium-shot-happy-people-winter.jpg", // replace with the correct path to your image
    },
    // Add more testimonials if needed
  ];
  return (
    <div id="testimonial">
      <Row className="clients">
        <Col xl={7} lg={7} sm={12}>
          <div className="img">
            <h2 className="page-header">What Our Clients Say</h2>
            <div className="cards">
              <div>10,000+</div>
              <p>Clients</p>
            </div>
            <div className="cards">
              <div>3,500+</div>
              <p>Successful Trips</p>
            </div>
          </div>
        </Col>
        <Col xl={5} lg={5} sm={12}>
          <Carousel>
            {testimonials.map((testimonial, index) => (
              <Carousel.Item key={index}>
                <div className="testimonial-card">
                  <img
                    className="w-100"
                    src={testimonial.image}
                    alt="Testimonial"
                  />
                  <div className="content">
                    <h3>{testimonial.name}</h3>
                    <h5>{testimonial.location}</h5>
                    <p>{testimonial.quote}</p>
                  </div>
                </div>
              </Carousel.Item>
            ))}
          </Carousel>
        </Col>
      </Row>
    </div>
  );
}

export default Clients;
