import "./App.scss";
import { Container } from "react-bootstrap";
import PreHeader from "./Pre-Header/PreHeader";
import NavHeader from "./Nav/NavHeader";
import Home from "./Home/Home";
import Destinations from "./Destinations/Destinations";
import Aboutus from "./Aboutus/Aboutus";
import Why from "./Why/Why";
import Service from "./Service/Service";
import Clients from "./Clients/Clients";
import Contact from "./Contact/Contact";
import { useEffect } from "react";
import Footer from "./Footer/Footer";

function App() {
  // useEffect(() => {
  //   const handleScroll = (e: any) => {
  //     const scrollTop = window.scrollY;
  //     const x = e.clientY;
  //     console.log(x);
  //     if (scrollTop === 0 && x > 50) {
  //       document.getElementById("navbar")?.classList.remove("fixed-top");
  //     } else {
  //       document.getElementById("navbar")?.classList.add("fixed-top");
  //     }
  //   };

  //   document.addEventListener("mousemove", handleScroll);
  //   return () => {
  //     document.removeEventListener("mousemove", handleScroll);
  //   };
  // }, []);
  return (
    <Container fluid className="App">
      <PreHeader></PreHeader>
      <NavHeader></NavHeader>
      <Home></Home>
      <Aboutus></Aboutus>
      <Why></Why>
      <Service></Service>
      <Clients></Clients>
      <Contact></Contact>
    </Container>
  );
}

export default App;
