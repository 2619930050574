import NavHeader from "../Nav/NavHeader";
import "./Home.scss";
import { Row, Col, Nav } from "react-bootstrap";
function Home() {
  return (
    <Row id="home">
      <Col className="home-content">
        <div className="discover-the-world-container">
          <span className="discover-the">Discover the</span>
          <span> </span>
          <span className="world-with-ease">World with Ease</span>
        </div>
        <Row>
          <Col lg={1} md={1} sm={0}></Col>
          <Col lg={4} md={6} sm={12}>
            <div className="home-description">
              Our mission is to make your travel experiences smooth, enjoyable,
              and unforgettable. Explore, plan, and book your next adventure
              with ease. At eTripEasy, your perfect trip is just a few clicks
              away.
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={1} md={1} sm={0}></Col>
          <Col lg={4} md={6} sm={12}>
            <br />
            <Nav.Link href="#contact" className="btn-theme">
              Enquire Now
            </Nav.Link>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Home;
