import "./Destinations.scss";
import { Row, Col } from "react-bootstrap";
function Destinations() {
  return (
    <Row id="destinations">
      <Col className="contain" sm={11} md={11} lg={11}>
        <Row className="des-cards">
          <Col className="des-card" xs={6} md={2}>
            <div className="des">Top Destinations</div>
            <div className="divieder"></div>
          </Col>
          <Col className="des-card" xs={6} md={2}>
            <div className="des-img">
              <img src="../../Assets/Europe.svg" />
              <div>Europe</div>
            </div>
            <div className="divieder"></div>
          </Col>
          <Col className="des-card" xs={6} md={2}>
            <div className="des-img">
              <img src="../../Assets/Asia.svg" />
              <div>Asia</div>
            </div>
            <div className="divieder"></div>
          </Col>
          <Col className="des-card" xs={6} md={2}>
            <div className="des-img">
              <img src="../../Assets/America.svg" />
              <div>America</div>
            </div>
            <div className="divieder"></div>
          </Col>
          <Col className="des-card" xs={6} md={2}>
            <div className="des-img">
              <img src="../../Assets/Africa.svg" />
              <div>Africa</div>
            </div>
            <div className="divieder"></div>
          </Col>
          <Col className="des-card" xs={6} md={2}>
            <div className="des-img">
              <img src="../../Assets/Oceania.svg" />
              <div>Oceania</div>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default Destinations;
